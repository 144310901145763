export default {
    settings: {
        env: "PROD", // PROD or DEV
        api_host_dev: "https://staging-pes-api.saphera.com",
        api_host_prod: "https://pes-api.saphera.com",
        api_path: "/api/v1/",
        api_request_header_pagination: "x-pagination",
    },
    api() {
        // Set environment variables
        var host = "";
        if (this.settings.env === "DEV") {
            host = this.settings.api_host_dev;
        } else {
            host = this.settings.api_host_prod;
        }

        const route = host + this.settings.api_path;

        return {
            authenticate: route + "authenticate",
            checkToken: route + "checkToken",
            contacts: route + "contacts",
            schools: route + "schools",
            reps: route + "SalesReps/sales-reps",
            orders: route + "orders",
            boxes: route + "boxes",
            isProduction: route + "isproduction",
            reports: route + "reports",
            graph: route + "graphs",
            signon: route + "init-signon",
            meta: {
                provinces: route + "meta/provinces",
                boards: route + "meta/boards",
                schoolyears: route + "meta/school-years",
                contactTitles: route + "meta/contact-titles",
                contactroles: route + "meta/contact-roles",
                salesrep: route + "meta/sales-reps",
            },
            pagination: this.settings.api_request_header_pagination,
            contractTemplate: route + "contract-template",
            contract: route + "contracts",
            mapping_schools: route + "mapping",
        };
    },
};
